import React, { useEffect, useState } from 'react';
import LinkHub from '@components/Dashboard/LinkHubs/LinkHub';
import { RouteComponentProps, Router } from '@reach/router';
import { makeGetRequest } from '@helpers/requests';
import { LinkHub as LinkHubData } from '@models/linkHub';
import { useSnackbar } from '@components/common/Snackbar';
import { LINK_HUB } from '@helpers/api';
import Public from '@components/common/Layouts/Public';
import Loader from '@components/common/Loader';

const LinkHubView: React.FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
	const [openSnackbar] = useSnackbar();
	const [loading, setLoading] = useState<boolean>(true);
	const [linkHub, setLinkHub] = useState<LinkHubData>();

	useEffect(() => {
		// TODO: Make get request for card data

		(async () => {
			try {
				if (!id) {
					// TODO: Redirect user away to a more ideal page than a snackbar error.
					openSnackbar('No Link Hub was given.', 'error');
					return;
				}

				const { data: linkHubData } = await makeGetRequest(LINK_HUB(id));
				setLinkHub(linkHubData);

				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve this card.',
						'error'
					);
				}
			}
		})();
	}, []);

	return <Public>{loading || !linkHub ? <Loader /> : <LinkHub linkHub={linkHub} />}</Public>;
};

const LinkHubRouter: React.FC = () => {
	return (
		<Router>
			<LinkHubView path="/l/:id" />
		</Router>
	);
};
export default LinkHubRouter;
