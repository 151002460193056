import { css } from '@emotion/react';
import { alpha, Theme } from '@mui/material';
import { darken, lighten, meetsContrastGuidelines, readableColor } from 'polished';

export default (
	theme: Theme,
	buttonsColour: string,
	colour: string,
	backgroundImage?: string
) => css`
	display: flex;
	height: 100%;
	background: transparent;
	min-height: 0;
	flex-grow: 1;

	.preview-background {
		display: flex;
		height: 100%;
		width: 100%;
		min-height: 0;
		flex-grow: 1;
	}

	.background {
		&.public {
			width: 100%;
		}

		background-color: ${colour};
		position: relative;

		&.background-dotted {
			background-image: radial-gradient(${lighten(0.1, colour)} 20%, transparent 20%),
				radial-gradient(${darken(0.1, colour)} 20%, transparent 20%);
			background-position: -7px 0, 18px 25px;
			background-size: 50px 50px;
		}
		&.background-gradient {
			background-image: linear-gradient(180deg, ${colour} 0%, ${lighten(0.35, colour)} 100%);
		}
		&.background-stripes {
			background: repeating-linear-gradient(
				45deg,
				${colour},
				${colour} 10px,
				${darken(0.1, colour)} 10px,
				${darken(0.1, colour)} 20px
			);
		}
		&.background-custom {
			background: center / cover url(${backgroundImage}) no-repeat;
		}

		.link-hub-loader {
			position: absolute;
			top: 20px;
			left: 20px;
			z-index: 5000;
			color: #1de9b6;
		}
	}

	.button {
		&.button-radius-square {
			border-radius: 0;
		}

		&.button-radius-rounded {
			border-radius: ${theme.spacing(1)};
		}

		&.button-radius-circular {
			border-radius: ${theme.spacing(6)};
		}

		&.button-variant-shadow {
			box-shadow: ${theme.shadows[4]};
		}

		&.button-variant-contained {
			background: ${buttonsColour};
			box-shadow: ${meetsContrastGuidelines(
				buttonsColour,
				theme.palette.mode === 'light'
					? theme.palette.common.white
					: theme.palette.common.black
			)?.AA
				? 'none'
				: theme.shadows[3]};
			color: ${readableColor(buttonsColour)};
			&:hover {
				background-color: ${darken(0.075, buttonsColour)};
			}
		}

		&.button-variant-outlined {
			border: 2px solid ${buttonsColour};
			border-color: ${meetsContrastGuidelines(
				buttonsColour,
				theme.palette.mode === 'light'
					? theme.palette.common.white
					: theme.palette.common.black
			)?.AA
				? buttonsColour
				: darken(0.1, buttonsColour)};
			color: ${buttonsColour};
			&:hover {
				background-color: ${alpha(buttonsColour, 0.2)};
			}
		}
		&:hover {
			background-color: ${darken(0.075, buttonsColour)};
		}
		&.button-variant-shadow {
			background: ${buttonsColour};
			color: ${readableColor(buttonsColour)};
			box-shadow: ${theme.shadows[10]};
			&:hover {
				background-color: ${darken(0.075, buttonsColour)};
			}
		}

		&.button-font-family-roboto {
			font-family: 'Roboto', sans-serif;
		}
		&.button-font-family-openSans {
			font-family: 'Open Sans', sans-serif;
		}
		&.button-font-family-inconsolata {
			font-family: 'Inconsolata', monospace;
		}
		&.button-font-family-yomogi {
			font-family: 'Yomogi', cursive;
		}
		&.button-font-family-ptSans {
			font-family: 'PT Sans', sans-serif;
		}
		&.button-font-family-quicksand {
			font-family: 'Quicksand', sans-serif;
		}
		&.button-font-weight-thin {
			font-weight: 100;
		}
		&.button-font-weight-regular {
			font-weight: 400;
		}
		&.button-font-weight-bold {
			font-weight: 700;
		}
	}

	.link-hub-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 400px;
		margin: 0 auto;
		width: 100%;
		min-height: 0;
		flex-grow: 1;
		&.public {
			min-height: 100%;
			padding: ${theme.spacing(4, 0)};
		}
		background: transparent;
		padding: ${theme.spacing(0.5, 2)};

		.link-hub-links-wrapper {
			flex-grow: 1;
			min-height: 0;
			display: flex;
			padding: ${theme.spacing(2, 0, 1)};
			&.public {
				padding: ${theme.spacing(4, 0, 2)};
			}
		}

		.link-hub-avatar {
			width: 96px;
			height: 96px;
		}

		.link-hub-name {
			color: ${theme.palette.text.primary};
			font-weight: bold;
		}

		.link-hub-links {
			list-style-type: none;
			margin: 0;
			padding: 0;
			width: 100%;
			overflow-y: auto;
			::-webkit-scrollbar {
				display: none;
			}

			.link-hub-links-link {
				margin-top: ${theme.spacing(4)};

				&:first-of-type {
					margin-top: 0;
				}
			}
		}

		.link-hub-social-links {
			margin-top: auto;

			svg {
				font-size: 1.2rem;
			}
		}

		.link-hub-logo {
			width: 50%;
			display: flex;
			align-items: center;
			margin-top: ${theme.spacing(1)};
		}
	}
`;
