import React from 'react';
import { Avatar, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import styles from './styles';
import { useTheme } from '@mui/material';
import { LinkHub as LinkHubType } from '@models/linkHub';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, readableColor } from 'polished';
import { Link } from 'gatsby';
import WaveCard from '@assets/logo/logo-full-black.inline.svg';
import { USER_AVATAR } from '@helpers/api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const LinkHub: React.FC<{
	linkHub: LinkHubType;
	preview?: boolean;
	isPublic?: boolean;
}> = ({ linkHub, preview, isPublic }) => {
	const theme = useTheme();

	const userName = linkHub?.meta?.created?.user?.firstName ?? 'username';
	const backgroundColour = linkHub?.appearance?.background?.colour ?? theme.palette.primary.main;
	const buttonsColour = linkHub?.appearance?.buttons?.colour ?? theme.palette.primary.main;
	const fontFamily = linkHub?.appearance?.font?.family ?? theme.typography.fontFamily;
	const fontWeight = linkHub?.appearance?.font?.weight ?? theme.typography?.fontWeightRegular;
	const backgroundVariant = linkHub?.appearance?.background?.variant;
	const buttonsVariant = linkHub?.appearance?.buttons?.variant ?? 'contained';
	const buttonsRadius = linkHub?.appearance?.buttons?.radius ?? 'rounded';
	const backgroundImage =
		linkHub?.appearance?.background?.variant === 'custom'
			? linkHub?.appearance?.background?.url
			: '';

	return (
		<div css={styles(theme, buttonsColour, backgroundColour, backgroundImage)}>
			<div
				className={
					preview
						? 'preview-background'
						: `background background-${backgroundVariant} public`
				}
			>
				<div className={`link-hub-content ${preview ? '' : 'public'}`}>
					<Stack
						direction="column"
						sx={{ flexGrow: 1, width: '100%', minHeight: 0 }}
						justifyContent="space-between"
					>
						<Box>
							<Stack direction="column" spacing={2} alignItems="center">
								<Avatar
									className="link-hub-avatar"
									alt={`${userName}'s avatar`}
									src={
										linkHub?.avatar ??
										USER_AVATAR(linkHub?.meta?.created?.user?._id)
									}
								/>

								<Typography
									component="h1"
									variant="body1"
									sx={{
										color: readableColor(backgroundColour),
										fontWeight: theme.typography.fontWeightMedium,
									}}
								>
									{linkHub?.title}
								</Typography>
							</Stack>
						</Box>
						<Box className={`link-hub-links-wrapper ${preview ? '' : 'public'}`}>
							<ul className="link-hub-links">
								{linkHub?.links
									?.filter((link) => link?.active === true && link?.name)
									?.map((link, index) => {
										return (
											<li className="link-hub-links-link" key={index}>
												<Button
													className={`button button-radius-${buttonsRadius} button-variant-${buttonsVariant} button-font-family-${fontFamily} button-font-weight-${fontWeight}`}
													fullWidth
													size="small"
													component="a"
													href={link?.url}
													rel="noopener noreferrer"
													target={preview ? '_blank' : ''}
													sx={{
														fontFamily: fontFamily,
														fontWeight: fontWeight,
														textAlign: 'center',
													}}
												>
													{link?.name ? link?.name : 'Name your new link'}
												</Button>
											</li>
										);
									})}
							</ul>
						</Box>
						<Box>
							<Stack direction="column" spacing={1} alignItems="center">
								{!!linkHub?.social?.length && (
									<Stack direction="row" className="link-hub-social-links">
										{linkHub?.social?.map((socialIcon, index) => {
											const socialType =
												socialIcon?.type === 'linkedIn'
													? 'linkedin'
													: socialIcon?.type === 'gitHub'
													? 'github'
													: socialIcon?.type === 'tikTok'
													? 'tiktok'
													: socialIcon?.type;

											return (
												<IconButton
													key={index}
													sx={{
														color: readableColor(
															backgroundColour,
															darken(0.075, backgroundColour),
															lighten(0.25, backgroundColour)
														),
													}}
													component={Link}
													to={socialIcon?.url}
												>
													<FontAwesomeIcon
														icon={['fab', socialType] as IconProp}
													/>
												</IconButton>
											);
										})}
									</Stack>
								)}
								{!linkHub?.appearance?.logo?.hide && (
									<Box className="link-hub-logo">
										<WaveCard />
									</Box>
								)}
							</Stack>
						</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default LinkHub;
